import { useState, useEffect, useMemo } from 'react';

import { cleanupExternalTooltip } from '@core/utils/graphTooltip';

// md matching styling in https://github.com/readmeio/readme/blob/f2fb12e8ee13d959b7dd8106885cf55d3f8ed261/packages/react/src/ui/Metrics/Graph/style.module.scss#L145
const TOOLTIP_PADDING = 20;

function useGraphTooltip(chart, tooltip) {
  const [isVisible, setIsVisible] = useState(tooltip.opacity !== 0);

  function hideTooltip(e) {
    // Hide tooltip for all non-keyboard events & only for specific key events that could trigger a navigation change ('Enter') or just make sense ('Escape')
    if (e.code == null || e.code === 'Enter' || e.code === 'Escape') setIsVisible(false);
  }

  useEffect(() => {
    if (chart) setIsVisible(tooltip.opacity !== 0);

    return () => {
      // Cleanup tooltip div element on unmount of tooltip so element doesn't persist on page
      cleanupExternalTooltip();
    };
  }, [chart, tooltip.opacity]);

  useEffect(() => {
    // Hide tooltip when going back/forward or on specific keysdown events
    window.addEventListener('popstate', hideTooltip, false);
    window.addEventListener('keydown', hideTooltip, false);
    return () => {
      window.removeEventListener('popstate', hideTooltip);
      window.removeEventListener('keydown', hideTooltip);
    };
  }, []);

  const boundingRect = chart?.canvas?.getBoundingClientRect();

  // Get the x position of the tooltip from caret x position (where tooltip points to on chart)
  const tooltipXPosition = tooltip?.caretX || 0;

  /**
   * Buffer area to the right of tooltips, roughly equal to max width of tooltips
   * Note: we don't have ability to get actual width of the tooltip since we don't have access to DOM element here when using external tooltip mode
   * @see: https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
   */
  const RIGHT_SIDE_BUFFER = 350;

  // Check if there's room to the right in the viewport
  const rightSpace = window.innerWidth - (boundingRect.x + tooltipXPosition);

  const shouldPositionLeft = rightSpace < RIGHT_SIDE_BUFFER;

  const y = (boundingRect?.y || 0) + window.scrollY;
  const x = (boundingRect?.x || 0) + window.scrollX;

  const style = useMemo(
    () => ({
      left: `${x + tooltip.caretX}px`,
      top: `${y + tooltip.caretY}px`,
      transform: shouldPositionLeft
        ? `translate(calc(-100% - ${TOOLTIP_PADDING}px), -${TOOLTIP_PADDING}px)`
        : `translate(${TOOLTIP_PADDING}px, -${TOOLTIP_PADDING}px)`,
      font: tooltip.options.bodyFont.string,
      padding: `${tooltip.padding}px ${tooltip.padding}px`,
      zIndex: 'var(--z-dropdown)',
    }),
    [tooltip.caretX, tooltip.caretY, tooltip.options.bodyFont.string, tooltip.padding, x, y, shouldPositionLeft],
  );

  return { isVisible, style };
}

export default useGraphTooltip;
