import React, { useEffect, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';

import Tooltip from '@ui/Tooltip';

import styles from '../style.module.scss';

interface Props {
  /** Filter name */
  name: string;
}

const FilterContentWithTooltip = ({ name }: Props) => {
  const bem = useClassy(styles, 'Filter');
  const [showTooltip, setShowTooltip] = useState(false);

  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = elementRef.current;

    // If content has been truncated (has overflow content hidden), show tooltip
    if (element && element?.scrollWidth > element?.clientWidth) {
      setShowTooltip(true);
    }
  }, [elementRef]);

  // Only show tooltip if content has been truncated
  if (showTooltip) {
    return (
      <Tooltip asTitle content={name} placement="top-start">
        <div className={bem('-name')}>{name}</div>
      </Tooltip>
    );
  }

  return (
    <div ref={elementRef} className={bem('-name')}>
      {name}
    </div>
  );
};

export default React.memo(FilterContentWithTooltip);
