import { debounce } from 'lodash';
import React, { useMemo, useState, useCallback } from 'react';
import { VariableSizeList as VirtualList } from 'react-window';

import useClassy from '@core/hooks/useClassy';
import type { APIKey, Group } from '@core/types/metrics';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuItem, MenuHeader, MenuDivider } from '@ui/Menu';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';

import styles from './style.module.scss';

const VIRTUAL_LIST_ITEM_SIZE = 50;

interface Props {
  activeAPIKey: string;
  /** Accepts API Keys in both { key, label } (Dash) and { id, name } (Hub) formats */
  apiKeys: APIKey[] | Group[];
  /** Whether to disable button */
  disabled?: boolean;
  menuHeader: string;
  onClick: (key: string) => void;
  showOutline?: boolean;
  /** Optional override prop for <Menu> theme */
  theme?: 'dark';
}

const APIKeyDropdown = ({
  activeAPIKey,
  apiKeys,
  disabled,
  menuHeader,
  onClick,
  showOutline = false,
  theme,
}: Props) => {
  const bem = useClassy(styles, 'APIKeyDropdown');
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');

  const debouncedSetSearchTerm = useMemo(() => debounce((value: string) => setSearchTerm(value), 150), []);

  const filteredKeys = useMemo(() => {
    if (!searchTerm.trim()) return apiKeys;
    return apiKeys.filter(key => {
      const isGroupFormat = 'id' in key;
      const label = String(isGroupFormat ? (key as Group).name : (key as APIKey).label);
      const keyValue = isGroupFormat ? (key as Group).id : (key as APIKey).key;
      const search = searchTerm.toLowerCase();
      return label.toLowerCase().includes(search) || keyValue.toLowerCase().includes(search);
    });
  }, [apiKeys, searchTerm]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const value = e.target.value;
      setInputValue(value);
      debouncedSetSearchTerm(value);
    },
    [debouncedSetSearchTerm],
  );

  const listHeight = useMemo(() => {
    if (!filteredKeys?.length) return 0;
    return filteredKeys.length >= 10 ? VIRTUAL_LIST_ITEM_SIZE * 10 : VIRTUAL_LIST_ITEM_SIZE * filteredKeys.length;
  }, [filteredKeys]);

  // If there's only one key, we don't need to render the dropdown
  if (apiKeys.length <= 1) {
    return (
      <Flex align="center" className={bem('-one-key')} gap="xs" layout="row">
        <Icon aria-label="Example active API key" color="gray70" name="key" size="md" strokeWeight={3} />
        {activeAPIKey ? (
          <ObfuscatedAPIKey
            allowCopy={false}
            allowExpansion={false}
            apiKey={activeAPIKey}
            conceal="before"
            displayLength={4}
          />
        ) : (
          'No API Key found'
        )}
      </Flex>
    );
  }

  return (
    <Dropdown align="top" appendTo={() => document.body} justify="start" sticky>
      <Button disabled={disabled} kind="secondary" outline={showOutline} size="sm" text={!showOutline}>
        <Icon color="gray70" name="key" strokeWeight={3} />
        <ObfuscatedAPIKey
          allowCopy={false}
          allowExpansion={false}
          apiKey={activeAPIKey}
          conceal="before"
          displayLength={4}
        />
        <Icon color="gray70" name="chevron-down" strokeWeight={3} />
      </Button>

      <Menu className={bem('-menu')} theme={theme}>
        <MenuHeader className={bem('-menuHeader')}>{menuHeader}</MenuHeader>

        <MenuItem className={bem('-search')} focusable={false} TagName="label">
          <Input
            aria-label="Search API Keys"
            onChange={handleSearchChange}
            onClick={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            placeholder="Filter"
            size="sm"
            type="search"
            value={inputValue}
          />
        </MenuItem>
        <MenuDivider />

        {filteredKeys.length > 0 ? (
          <VirtualList height={listHeight} itemCount={filteredKeys.length} itemSize={() => 50} width="100%">
            {({ index, style }) => {
              const isGroupFormat = 'id' in filteredKeys[index];
              const key = isGroupFormat ? (filteredKeys[index] as Group).id : (filteredKeys[index] as APIKey).key;
              const label = String(
                isGroupFormat ? (filteredKeys[index] as Group).name : (filteredKeys[index] as APIKey).label,
              );
              const isActive = activeAPIKey === key;

              return (
                <div style={style}>
                  <MenuItem active={isActive} onClick={() => onClick(key)}>
                    <Flex className={bem('-menuItem-key', isActive && '-menuItem-key_active')} gap="xs" layout="col">
                      <ObfuscatedAPIKey
                        allowCopy={false}
                        allowExpansion={false}
                        apiKey={key}
                        conceal="before"
                        displayLength={4}
                      />
                      <div className={bem('-menuItem-label')} title={label}>
                        {label}
                      </div>
                    </Flex>
                  </MenuItem>
                </div>
              );
            }}
          </VirtualList>
        ) : (
          <MenuItem className={bem('-empty')} focusable={false} TagName="div">
            No matching API keys found
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
};

export default APIKeyDropdown;
